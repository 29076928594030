var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import { ExportAssistManagementService } from "~/services/dataimp-service/export-assist-management.service";
import { PeriodTransformService } from "~/services/management-service/period-transform.service";
var ApplicableConversionTemplate = /** @class */ (function (_super) {
    __extends(ApplicableConversionTemplate, _super);
    function ApplicableConversionTemplate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.exportTypeList = [];
        _this.submitLoading = false;
        _this.Model = {
            items: [{ key: "", value: "" }],
            principalId: "",
            principalName: "",
            tramsformMap: {} // 新增的系统
        };
        _this.rules = {
            principalId: [{ required: true, message: "请选择委托方" }]
        };
        return _this;
    }
    ApplicableConversionTemplate.prototype.modifyDataChange = function (value) {
        if (value.id) {
            this.Model = value;
            this.Model.principalId = value.principalName;
            if (!!value.tramsformMap) {
                var dataAny = value.tramsformMap;
                var a = Object.entries(dataAny);
                var items = [];
                for (var _i = 0, a_1 = a; _i < a_1.length; _i++) {
                    var i = a_1[_i];
                    items.push({
                        key: i[0],
                        value: i[1]
                    });
                }
                this.Model.items = items;
            }
        }
        else {
            return;
        }
    };
    ApplicableConversionTemplate.prototype.close = function () { };
    ApplicableConversionTemplate.prototype.success = function () {
        this.submitLoading = false;
        this.close();
    };
    /**
     * 新增模板
     */
    ApplicableConversionTemplate.prototype.newTemplate = function () {
        var newdata = {
            key: "",
            value: ""
        };
        this.Model.items.push(newdata);
        // let entities = Object.entries(this.Model.items);
    };
    /**
     * 删除模板
     */
    ApplicableConversionTemplate.prototype.deleteClick = function (data) {
        this.Model.items.splice(data.$index, 1);
    };
    /**
     * 提交
     */
    ApplicableConversionTemplate.prototype.submit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return false;
            _this.submitLoading = true;
            var itemsModel = {};
            var tempItems = Object.assign([], _this.Model.items);
            if (tempItems) {
                tempItems.forEach(function (item) {
                    var entry = Object.values(Object.assign({}, item));
                    itemsModel[entry[0]] = entry[1];
                });
                _this.Model.tramsformMap = itemsModel;
            }
            if (_this.Model.id) {
                _this.Model.newprincipalId = _this.principalList.find(function (x) { return x.principalName === _this.Model.principalId; }).id;
                _this.periodTransformService
                    .modifyTransformTemplate(_this.Model)
                    .subscribe(function (data) {
                    _this.$message.success("修改成功!");
                    _this.success();
                }, function (err) { return (_this.submitLoading = false); });
            }
            else {
                _this.Model.principalName = _this.principalList.find(function (x) { return x.id === _this.Model.principalId; }).principalName;
                _this.periodTransformService
                    .createTransformTemplate(_this.Model)
                    .subscribe(function (data) {
                    _this.$message.success("新增成功!");
                    _this.success();
                }, function (err) { return (_this.submitLoading = false); });
            }
        });
    };
    /**
     * 表单重置
     */
    ApplicableConversionTemplate.prototype.reset = function () {
        var form = this.$refs["form"];
        form.resetFields();
        this.Model = {
            items: [],
            principalId: "",
            principalName: ""
        };
    };
    __decorate([
        Dependencies(PeriodTransformService)
    ], ApplicableConversionTemplate.prototype, "periodTransformService", void 0);
    __decorate([
        Dependencies(ExportAssistManagementService)
    ], ApplicableConversionTemplate.prototype, "exportAssistManagementService", void 0);
    __decorate([
        State
    ], ApplicableConversionTemplate.prototype, "principalList", void 0);
    __decorate([
        Prop()
    ], ApplicableConversionTemplate.prototype, "modifyData", void 0);
    __decorate([
        Watch("modifyData", { immediate: true })
    ], ApplicableConversionTemplate.prototype, "modifyDataChange", null);
    __decorate([
        Emit()
    ], ApplicableConversionTemplate.prototype, "close", null);
    __decorate([
        Emit()
    ], ApplicableConversionTemplate.prototype, "success", null);
    ApplicableConversionTemplate = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], ApplicableConversionTemplate);
    return ApplicableConversionTemplate;
}(Vue));
export default ApplicableConversionTemplate;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { State } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { Layout, Dependencies } from "~/core/decorator";
import ApplicableConversionTemplate from "~/components/system-manage/principal-manage/applicable-conversion-template.vue";
import { PeriodTransformService } from "~/services/management-service/period-transform.service";
var ApplicableConversion = /** @class */ (function (_super) {
    __extends(ApplicableConversion, _super);
    function ApplicableConversion() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 搜索model
        _this.Model = {
            principalId: "",
        };
        _this.dataSet = [];
        _this.dialog = {
            exportConfig: false
        };
        _this.modifyData = {};
        return _this;
    }
    ApplicableConversion.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 刷新数据
     */
    ApplicableConversion.prototype.refreshData = function () {
        var _this = this;
        this.periodTransformService
            .getAllTransformTemplate(this.Model, this.pageService)
            .subscribe(function (data) {
            _this.dataSet = data;
        }, function (err) {
            _this.$message.error(err.msg);
        });
    };
    /**
     * 删除模板
     */
    ApplicableConversion.prototype.deleteClick = function (row) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u5220\u9664\u8FD9\u6761\u6570\u636E\u5417\uFF1F", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            // 发送删除数据请求
            _this.periodTransformService.deleteTemplateById(row.id).subscribe(function (data) {
                _this.$message({
                    type: "success",
                    message: "删除成功!"
                });
                // 更新数据信息
                _this.refreshData();
            }, function (err) { });
        })
            .catch(function () { });
    };
    /**
     * 修改模版
     */
    ApplicableConversion.prototype.updateClick = function (row) {
        this.modifyData = Object.assign({}, row);
        this.dialog.exportConfig = true;
    };
    ApplicableConversion.prototype.exportConfigClose = function () {
        this.modifyData = {};
        var exportConfig = this.$refs["applicable-conversion-template"];
        exportConfig.reset();
    };
    __decorate([
        Dependencies(PageService)
    ], ApplicableConversion.prototype, "pageService", void 0);
    __decorate([
        Dependencies(PeriodTransformService)
    ], ApplicableConversion.prototype, "periodTransformService", void 0);
    __decorate([
        State
    ], ApplicableConversion.prototype, "principalList", void 0);
    ApplicableConversion = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ApplicableConversionTemplate: ApplicableConversionTemplate,
            }
        })
    ], ApplicableConversion);
    return ApplicableConversion;
}(Vue));
export default ApplicableConversion;
